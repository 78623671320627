import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import type {
  FinishInventoryTaskPayload,
  ManualInventorySkuParams,
  ManualInventorySkuWithRealStockDto,
} from './manual-inventory.dto';
import { manualInventoryService } from './manual-inventory.service';
import { addListToConfirmData } from './manual-inventory.utils';

/**
 * 获取SKU库存信息
 */
export const useGetSkuStockInfo = () => {
  return useMutation({
    mutationFn: async (params: ManualInventorySkuParams) => {
      return await manualInventoryService.getSkuStockInfo(params);
    },
  });
};

/**
 * 结束盘点
 */
export const useFinishManualInventory = () => {
  return useMutation({
    mutationFn: async (payload: FinishInventoryTaskPayload) => {
      await manualInventoryService.finishInventoryTask(payload);
    },
  });
};

/**
 * 获取选中的仓库id，同时设置选中的仓库id
 * 目前：手动修改仓位、任务修改仓位、手动盘点 共用一套设置
 */
export const useSkuWarehouseId = () => {
  const [warehouseId, setWarehouseId] = useState<number>();

  useEffect(() => {
    manualInventoryService.getSelectedWarehouseId().then((id) => {
      if (id) {
        setWarehouseId(+id);
      }
    });
  }, []);

  /**
   * 保存选中的仓库id
   */
  const saveWarehouseId = async (id: number) => {
    await manualInventoryService.setSelectedWarehouseId(id);
    setWarehouseId(id);
  };

  return {
    warehouseId,
    setWarehouseId,
    saveWarehouseId,
  };
};

export const useConfirmData = () => {
  const [confirmData, setConfirmData] = useState<
    ManualInventorySkuWithRealStockDto[]
  >([]);

  // 返回统计信息
  // totalSkuCode: 已盘点种类
  // totalRealStock: 已盘点数量
  const statistics = useMemo(() => {
    const skuCodeSet = new Set<string>();
    let totalRealStock = 0;

    confirmData.forEach((item) => {
      skuCodeSet.add(item.sku_code);
      totalRealStock += item.real_stock;
    });

    return {
      totalSkuCode: skuCodeSet.size,
      totalRealStock,
    };
  }, [confirmData]);

  const addConfirmDataList = (data: ManualInventorySkuWithRealStockDto[]) => {
    setConfirmData((prev) => {
      return addListToConfirmData(data, prev);
    });
  };

  const clearConfirmData = () => {
    setConfirmData([]);
  };

  return {
    confirmData,
    addConfirmDataList,
    clearConfirmData,
    statistics,
  };
};
