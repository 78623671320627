import { format, isValid, parse, parseISO } from 'date-fns';
import type { FC } from 'react';

export interface FormatDatetimeOptions {
  /**
   * 原始字符串格式
   *
   * 当原始时间字符串为非 ISO8601 格式时需要指定
   */
  sourceFormat?: string;

  /**
   * 参考时间
   *
   * 当原始时间字符串为非 ISO8601 格式时需要指定
   *
   * 默认为当前时间
   */
  referenceDate?: number | Date;
}

/**
 * 将时间格式化为指定的格式
 *
 * @param value 需要格式化的时间
 * @param formatString 时间格式，默认为 yyyy-MM-dd HH:mm:ss
 * @param options 格式化配置
 * @returns
 */
export const formatDatetime = (
  value: Date | string | number,
  formatString = 'yyyy-MM-dd HH:mm:ss',
  options?: FormatDatetimeOptions,
) => {
  let date: Date | number | undefined;

  if (value instanceof Date) {
    date = value;
  }

  if (typeof value === 'string') {
    if (options?.sourceFormat) {
      date = parse(value, options.sourceFormat, new Date());
    } else {
      date = parseISO(value);
    }
  }

  if (!date) {
    return null;
  }

  if (!isValid(date)) {
    return null;
  }

  return format(date, formatString);
};

export interface DatetimeProps extends FormatDatetimeOptions {
  /**
   * 需要格式化的时间
   */
  value?: Date | string | number | null;

  /**
   * 时间格式
   *
   * 默认为 yyyy-MM-dd HH:mm:ss
   */
  format?: string;

  /**
   * 是否使用 '--' 替代
   */
  useDefault?: boolean;

  className?: string;
}

/**
 * 时间展示组件
 *
 * 默认时间格式为 yyyy-MM-dd HH:mm:ss
 */
export const Datetime: FC<DatetimeProps> = (props) => {
  if (
    props.value == null ||
    (typeof props.value === 'string' && !props.value)
  ) {
    if (props.useDefault === false) return null;
    return '--';
  }

  const datetime = formatDatetime(props.value, props.format, {
    sourceFormat: props.sourceFormat,
  });

  return (
    <span className={props.className} title={datetime ?? void 0}>
      {datetime}
    </span>
  );
};
