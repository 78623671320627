import type { Nullish, PrimaryKey } from '@erp-mobile/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useUserProfile } from '../user';
import type {
  SystemSettings,
  SystemSettingsInUserScope,
} from './system-settings.dto';
import { systemSettingsService } from './system-settings.service';

export const SYSTEM_SETTINGS = ['WAREHOUSE_SYSTEM_SETTINGS'];

export const useSystemSettings = () => {
  return useQuery({
    queryKey: SYSTEM_SETTINGS,
    queryFn: async () => {
      return systemSettingsService.get();
    },
    staleTime: 1000 * 3,
  });
};

export const useUpdateSystemSettings = () => {
  return useMutation({
    mutationFn: async (data: SystemSettings) => {
      return systemSettingsService.update(data);
    },
  });
};

/**
 * 是否可以修改仓位
 *
 * 规则：
 * 1、有库存：与配置项无关，所有用户均不可修改仓位
 * 2、无库存（0或负数）：账号需配置修改非空仓位，配置后，支持上架时候直接改仓位
 * 3、空仓位：与配置项无关，所有用户都可修改仓位
 *
 * @param sku.stock 库存
 * @param sku.positionId 仓位ID
 * @returns boolean
 */
export const useModifyPositionEnable = (sku: {
  stock: number;
  positionId: Nullish<PrimaryKey>;
}) => {
  const { data: systemData } = useSystemSettings();
  const { id } = useUserProfile();

  return isModifyPositionEnable({ sku, systemData, id });
};

/**
 * 是否可以修改仓位（getter）
 */
export const useModifyPositionEnableGetter = () => {
  const { data: systemData } = useSystemSettings();
  const { id } = useUserProfile();

  return useCallback(
    (sku: { stock: number; positionId: Nullish<PrimaryKey> }) => {
      return isModifyPositionEnable({ sku, systemData, id });
    },
    [systemData, id],
  );
};

const isModifyPositionEnable = (data: {
  sku: {
    stock: number;
    positionId: Nullish<PrimaryKey>;
  };
  systemData?: SystemSettings;
  id: number;
}) => {
  const { stock, positionId } = data.sku;
  const { systemData, id } = data;

  // 1、有库存：与配置项无关，所有用户均不可修改仓位（有库存，一定有仓位)
  if (stock > 0) {
    return false;
  }

  // 3、空仓位：与配置项无关，所有用户都可修改仓位
  // 注意：有些接口仓位id为0，也是空仓位
  if (positionId === null || positionId === undefined || positionId === 0) {
    return true;
  }

  // 2、无库存（0或负数）：账号需配置修改非空仓位，配置后，支持上架时候直接改仓位
  const isSetting = systemData?.inbound_setting_sku_position?.is_setting;

  // 未勾选，不可修改
  if (!isSetting) {
    return false;
  }

  const userId = systemData?.inbound_setting_sku_position?.user_id;

  // 全部用户
  if (userId === 1) {
    return true;
  }

  // 指定用户
  return userId.includes(id);
};

/**
 * 入库上架是否可修改入库量、破损量
 * @returns boolean
 */
export const useModifyInboundPutOnQuantityEnable = () => {
  const { data: systemData } = useSystemSettings();
  const { id } = useUserProfile();

  if (!systemData) {
    return false;
  }

  return isEnableInUserScope(id, systemData.modify_the_inventory_quantity);
};

/**
 * 退包上架是否可以修改入库量、破损量
 * @returns boolean
 */
export const useModifyBackPutOnQuantityEnable = () => {
  const { data: systemData } = useSystemSettings();
  const { id } = useUserProfile();

  if (!systemData) {
    return false;
  }

  return isEnableInUserScope(id, systemData.back_the_inventory_quantity);
};

/**
 * 是否在用户范围内启用
 * @param userId 当前用户id
 * @param setting 相关配置
 * @returns
 */
function isEnableInUserScope(
  userId: number,
  setting: SystemSettingsInUserScope,
): boolean {
  const { is_setting, user_id } = setting;

  if (!is_setting) {
    return false;
  }

  if (user_id === 1) {
    return true;
  }

  return user_id.includes(userId);
}
