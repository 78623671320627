import axios from 'axios';
import type {
  GetSignatureInPayload,
  MutationSignaturePayload,
  SignatureDto,
  SignaturePrintRespItem,
  SignaturePrintSkuPayload,
  SignatureQualityReportDto,
} from './signature.dto';

export class SignatureService {
  async getSignatureInList(
    payload?: GetSignatureInPayload,
  ): Promise<SignatureDto> {
    if (!payload || !payload.sign_order) {
      return {
        number_list: [],
        sign_skus: [],
      };
    }
    const { data } = await axios.get<SignatureDto>(`api/inbound/sign`, {
      params: payload,
    });

    return data;
  }

  // 完成签收
  async completeSignature(payload: MutationSignaturePayload) {
    await axios.post(`api/inbound/sign`, payload);
  }

  // 质检报告
  async qualityReport(payload: {
    purchase_number: string;
  }): Promise<SignatureQualityReportDto> {
    const { data } = await axios.get(`api/inbound/report`, {
      params: payload,
    });
    return data;
  }

  // 打印入库sku单
  async printSku(
    body: SignaturePrintSkuPayload,
  ): Promise<SignaturePrintRespItem[]> {
    const { data } = await axios.post('api/inbound/print/sku', body);
    return data;
  }

  /**
   * 验证任务码是否可用
   * @param taskCode 任务码
   */
  async checkTaskCodeAvailable(taskCode: string) {
    await axios.get(`api/inbound/task_code`, {
      params: {
        task_code: taskCode,
      },
    });
  }
}

const signatureService = new SignatureService();
export default signatureService;
