import type { ManualInventorySkuWithRealStockDto } from './manual-inventory.dto';

/**
 * 以sku_code 和 custom_text_code 作为唯一标识
 */
const toUniqKey = (item: ManualInventorySkuWithRealStockDto) => {
  // SKU code 不区分大小写，线上出现过扫描标签 大小写不一致的情况。导致重复盘点
  const upperCaseSkuCode = item.sku_code.toUpperCase();
  return `${upperCaseSkuCode}-${item.custom_text_code}`;
};

/**
 * 将 list 中的数据添加到 confirmData 中
 * 对应 confirmData 已存在的数据，以 list 中的数据为准
 */
export const addListToConfirmData = (
  list: ManualInventorySkuWithRealStockDto[],
  confirmData: ManualInventorySkuWithRealStockDto[],
) => {
  if (!list || !list.length) {
    return confirmData;
  }

  const newMap = new Map<string, ManualInventorySkuWithRealStockDto>();

  // 新加入的 list 如果sku_code和custom_text_code相同，以最新的数据为准
  for (const item of [...confirmData, ...list]) {
    newMap.set(toUniqKey(item), item);
  }

  return Array.from(newMap.values());
};
